<template>
  <svg :style="style" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="-1 -1 50 14" xml:space="preserve" class="text-charcoal-300 inline-block">
    <circle fill="currentColor" stroke="none" cx="6" cy="6" r="6">
      <animate
        attributeName="opacity"
        dur="1s"
        values="0.2;1;0.2"
        repeatCount="indefinite"
        begin="0.1"
      />
    </circle>
    <circle fill="currentColor" stroke="none" cx="24" cy="6" r="6">
      <animate
        attributeName="opacity"
        dur="1s"
        values="0.2;1;0.2"
        repeatCount="indefinite"
        begin="0.2"
      />
    </circle>
    <circle fill="currentColor" stroke="none" cx="42" cy="6" r="6">
      <animate
        attributeName="opacity"
        dur="1s"
        values="0.2;1;0.2"
        repeatCount="indefinite"
        begin="0.3"
      />
    </circle>
  </svg>
</template>

<script setup>
  const props = defineProps({
    width: {
      type: Number,
      default: 50
    }
  });

  const style = computed(() => ({width: `${props.width}px`}));
</script>
